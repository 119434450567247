<template>
  <div class="form-popup" @click="close()">
    <div @click.stop="" class="parent-form-container">
      <b-overlay :show="showOverlay" class="form-container">
        <header>
          <div class="header-part">
            <span> Ajouter un nouveau batiment</span>
            <i class="ik ik-x clos" @click="close()"></i>
          </div>
          <div class="progress-area">
            <div class="step-item">
              <span :class="{ active: step == first }">
                Informations de base
              </span>
              <div class="bullet" :class="{ active: step == first }">
                <span v-show="step == first">1</span>
                <div v-show="step > first">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == second }"> Localisation </span>
              <div class="bullet" :class="{ active: step == second }">
                <span v-show="step <= second">2</span>
                <div v-show="step > second">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == lastStep }"> Photos </span>
              <div class="bullet" :class="{ active: step == lastStep }">
                <span v-show="step <= 2">3</span>
                <div v-show="step > 3">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="main-form">
          <div class="error-popup" v-show="incompleteData">
            <i class="ik ik-alert-triangle"></i>
            <span>
              {{ customErrorMsg }}
            </span>
          </div>
          <section v-show="step == first" class="basic-data">
            <div class="input-container">
              <div class="input-item">
                <span>Nom du batiment*</span>
                <input
                  type="text"
                  placeholder="ex: batiment saint Gregoire"
                  ref="nomBat"
                />
              </div>
              <div class="input-item">
                <span>Superficie* ( en m² )</span>
                <input
                  type="number"
                  min="0"
                  required
                  placeholder="ex: 150m²"
                  v-model="superficie"
                />
              </div>
              <div class="input-item">
                <span>Réference du bâtiment*</span>
                <input
                  type="text"
                  placeholder="Une reference est propre à chaque batiment. Ex: BT001"
                  v-model="ref"
                />
              </div>
              <div class="input-item">
                <span>Votre batiment est-il associé à une cité ?</span>
                <select v-model="idCite">
                  <option :value="null" disabled>
                    Choisir si votre batiment est lié à une cité
                  </option>
                  <option v-if="idCite !== null" :value="null">
                    N'appartient à aucune cité
                  </option>
                  <option
                    v-for="(cite, cle) in cites"
                    :key="cle"
                    :value="cite.idCite"
                    class="batiment-option"
                  >
                    <span class="cite-name">
                      {{ cite.nomCite }}
                    </span>
                  </option>
                </select>
              </div>
            </div>
          </section>
          <section v-show="step == second" class="process-item location">
            <div class="map-area">
              <div class="map-container" ref="carte"></div>
              <div class="research-input" id="location">
                <div>
                  <i class="ik ik-search"></i>
                </div>
                <input
                  type="text"
                  id="location-input"
                  ref="location"
                  placeholder="Entrez la zone de localisation"
                />
              </div>
            </div>
            <div class="input-container">
              <div class="input-item">
                <span>Pays</span>
                <select v-model="pays">
                  <option
                    v-for="(country, cle) in tousLesPays"
                    :key="cle"
                    :value="country.libellePays"
                  >
                    {{ country.libellePays }}
                  </option>
                </select>
              </div>
              <div class="input-item">
                <span>Ville</span>
                <input type="text" placeholder="Yaoundé" v-model="ville" />
              </div>
              <div class="input-item">
                <span>Quartier</span>
                <input
                  type="text"
                  min="0"
                  placeholder="ex: Mvan"
                  v-model="quartier"
                />
              </div>
              <div class="more-location">
                <div style="margin-bottom: 20px">
                  <input type="checkbox" name="" id="" />
                  <span> Plus de critères </span>
                </div>
                <div class="semi-container" style="display: none">
                  <div class="input-item">
                    <span>Lattitude</span>
                    <input
                      type="number"
                      min="0"
                      placeholder="0.000000000000000"
                    />
                  </div>
                  <div class="input-item">
                    <span>Longitude</span>
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0000000000000000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section v-show="step == lastStep" class="process-item photos">
            <span>
              Ajouter des images de très bonnes qualités. <br />
              <b>Minimum 5 images</b> <br />
              c'est facultatif
            </span>
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :data-images="images"
              :dragText="$t('data.logement_dragText')"
              :browseText="$t('data.logement_browseText')"
              idUpload="myIdUpload"
              editUpload="myIdEdit"
            ></vue-upload-multiple-image>
          </section>
        </div>
        <div class="form-footer">
          <div class="cancel" @click="close()" v-show="step == 0">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div class="cancel" @click="back()" v-show="step > 0">
            <OutlinedDarkBtn buttonTitle="Précedent" />
          </div>
          <div class="validate" v-show="step !== lastStep" @click="nextStep()">
            <FullPinkBtn buttonTitle="Suivant" :loading=loading></FullPinkBtn>
          </div>
          <div class="validate" @click="submitForm()" v-show="step == lastStep">
            <FullPinkBtn buttonTitle="Créer le bâtiment" :loading=loading1></FullPinkBtn>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import notif from "@/plugins/notif.js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
const php = require("phpjs");
export default {
  components: {
    VueUploadMultipleImage,
    FullPinkBtn,
    OutlinedDarkBtn,
  },
  props: {
    batiment: { type: Object },
    editLogement: { type: Object },
    action: { type: String, default: "add" },
  },
  data: () => ({
    showOverlay: false,
    loading: false,
    loading1: false,
    step: 0,
    lastStep: 2,
    first: 0,
    second: 1,
    temoin: false,
    incompleteData: false,
    customErrorMsg: `Veuillez renseigner toutes les informations nécessaires.`,
    images: [],
    photos: [],
    markers: [],
    valeurPays: "Cameroun",
    cites: [],
    idCite: null,
    nom: "",
    ref: "",
    superficie: null,
    nomCite: null,
    refCite: "",
    pays: "Cameroun",
    ville: "",
    quartier: "",
    lat: "",
    lon: "",
    photo: { batiment: null, cite: null },
    selected_index_cite: null,
    tousLesPays: [
      {
        libellePays: "Afghanistan",
      },
      {
        libellePays: "Afrique du Sud",
      },
      {
        libellePays: "Albanie",
      },
      {
        libellePays: "Algérie",
      },
      {
        libellePays: "Allemagne",
      },
      {
        libellePays: "Andorre",
      },
      {
        libellePays: "Angola",
      },
      {
        libellePays: "Anguilla",
      },
      {
        libellePays: "Antarctique",
      },
      {
        libellePays: "Antigua-et-Barbuda",
      },
      {
        libellePays: "Antilles néerlandaises",
      },
      {
        libellePays: "Arabie saoudite",
      },
      {
        libellePays: "Argentine",
      },
      {
        libellePays: "Arménie",
      },
      {
        libellePays: "Aruba",
      },
      {
        libellePays: "Australie",
      },
      {
        libellePays: "Autriche",
      },
      {
        libellePays: "Azerbaïdjan",
      },
      {
        libellePays: "Bahamas",
      },
      {
        libellePays: "Bahreïn",
      },
      {
        libellePays: "Bangladesh",
      },
      {
        libellePays: "Barbade",
      },
      {
        libellePays: "Bélarus",
      },
      {
        libellePays: "Belgique",
      },
      {
        libellePays: "Belize",
      },
      {
        libellePays: "Bénin",
      },
      {
        libellePays: "Bermudes",
      },
      {
        libellePays: "Bhoutan",
      },
      {
        libellePays: "Bolivie",
      },
      {
        libellePays: "Bosnie-Herzégovine",
      },
      {
        libellePays: "Botswana",
      },
      {
        libellePays: "Brésil",
      },
      {
        libellePays: "Brunéi Darussalam",
      },
      {
        libellePays: "Bulgarie",
      },
      {
        libellePays: "Burkina Faso",
      },
      {
        libellePays: "Burundi",
      },
      {
        libellePays: "Cambodge",
      },
      {
        libellePays: "Cameroun",
      },
      {
        libellePays: "Canada",
      },
      {
        libellePays: "Cap-Vert",
      },
      {
        libellePays: "Ceuta et Melilla",
      },

      {
        libellePays: "Chili",
      },
      {
        libellePays: "Chine",
      },
      {
        libellePays: "Chypre",
      },
      {
        libellePays: "Colombie",
      },
      {
        libellePays: "Comores",
      },
      {
        libellePays: "Congo-Brazzaville",
      },
      {
        libellePays: "Corée du Nord",
      },
      {
        libellePays: '"Corée du Sud',
      },
      {
        libellePays: "Costa Rica",
      },
      {
        libellePays: "Côte d’Ivoire",
      },
      {
        libellePays: "Croatie",
      },
      {
        libellePays: "Cuba",
      },
      {
        libellePays: "Danemark",
      },
      {
        libellePays: "Diego Garcia",
      },
      {
        libellePays: "Djibouti",
      },
      {
        libellePays: "Dominique",
      },
      {
        libellePays: "Égypte",
      },
      {
        libellePays: "El Salvador",
      },
      {
        libellePays: "Émirats arabes unis",
      },
      {
        libellePays: "Équateur",
      },
      {
        libellePays: "Érythrée",
      },
      {
        libellePays: "Espagne",
      },
      {
        libellePays: "Estonie",
      },
      {
        libellePays: "État de la Cité du Vatican",
      },

      {
        libellePays: "États fédérés de Micronésie",
      },

      {
        libellePays: "États-Unis",
      },
      {
        libellePays: "Éthiopie",
      },
      {
        libellePays: "Fidji",
      },
      {
        libellePays: "Finlande",
      },
      {
        libellePays: "France",
      },
      {
        libellePays: "Gabon",
      },
      {
        libellePays: "Gambie",
      },
      {
        libellePays: "Géorgie",
      },
      {
        libellePays: "Géorgie du Sud et les îles Sandwich du Sud",
      },
      {
        libellePays: "Ghana",
      },
      {
        libellePays: "Gibraltar",
      },
      {
        libellePays: "Grèce",
      },
      {
        libellePays: "Grenade",
      },
      {
        libellePays: "Groenland",
      },
      {
        libellePays: "Guadeloupe",
      },
      {
        libellePays: "Guam",
      },
      {
        libellePays: "Guatemala",
      },
      {
        libellePays: "Guernesey",
      },
      {
        libellePays: "Guinée",
      },
      {
        libellePays: "Guinée équatoriale",
      },
      {
        libellePays: "Guinée-Bissau",
      },
      {
        libellePays: "Guyana",
      },
      {
        libellePays: "Guyane française",
      },
      {
        libellePays: "Haïti",
      },
      {
        libellePays: "Honduras",
      },
      {
        libellePays: "Hongrie",
      },

      {
        libellePays: "Île Bouvet",
      },
      {
        libellePays: "Île Christmas",
      },
      {
        libellePays: "Île Clipperton",
      },
      {
        libellePays: "Île de l'Ascension",
      },
      {
        libellePays: "Île de Man",
      },
      {
        libellePays: "Île Norfolk",
      },
      {
        libellePays: "Îles Åland",
      },
      {
        libellePays: "Îles Caïmans",
      },
      {
        libellePays: "Îles Canaries",
      },
      {
        libellePays: "Îles Cocos - Keeling",
      },
      {
        libellePays: "Îles Cook",
      },
      {
        libellePays: "Îles Féroé",
      },
      {
        libellePays: "Îles Heard et MacDonald",
      },
      {
        libellePays: "Îles Malouines",
      },
      {
        libellePays: "Îles Mariannes du Nord",
      },
      {
        libellePays: "Îles Marshall",
      },
      {
        libellePays: "Îles Mineures Éloignées des États-Unis",
      },
      {
        libellePays: "Îles Salomon",
      },
      {
        libellePays: "Îles Turks et Caïques",
      },
      {
        libellePays: "Îles Vierges britanniques",
      },
      {
        libellePays: "Îles Vierges des États-Unis",
      },
      {
        libellePays: "Inde",
      },
      {
        libellePays: "Indonésie",
      },
      {
        libellePays: "Irak",
      },
      {
        libellePays: "Iran",
      },
      {
        libellePays: "Irlande",
      },
      {
        libellePays: "Islande",
      },
      {
        libellePays: "Israël",
      },
      {
        libellePays: "Italie",
      },
      {
        libellePays: "Jamaïque",
      },
      {
        libellePays: "Japon",
      },
      {
        libellePays: "Jersey",
      },
      {
        libellePays: "Jordanie",
      },
      {
        libellePays: "Kazakhstan",
      },
      {
        libellePays: "Kenya",
      },
      {
        libellePays: "Kirghizistan",
      },
      {
        libellePays: "Kiribati",
      },
      {
        libellePays: "Koweït",
      },
      {
        libellePays: "Laos",
      },
      {
        libellePays: "Lesotho",
      },
      {
        libellePays: "Lettonie",
      },
      {
        libellePays: "Liban",
      },
      {
        libellePays: "Libéria",
      },
      {
        libellePays: "Libye",
      },
      {
        libellePays: "Liechtenstein",
      },
      {
        libellePays: "Lituanie",
      },
      {
        libellePays: "Luxembourg",
      },
      {
        libellePays: "Macédoine",
      },
      {
        libellePays: "Madagascar",
      },
      {
        libellePays: "Malaisie",
      },
      {
        libellePays: "Malawi",
      },
      {
        libellePays: "Maldives",
      },
      {
        libellePays: "Mali",
      },
      {
        libellePays: "Malte",
      },
      {
        libellePays: "Maroc",
      },
      {
        libellePays: "Martinique",
      },
      {
        libellePays: "Maurice",
      },
      {
        libellePays: "Mauritanie",
      },
      {
        libellePays: "Mayotte",
      },
      {
        libellePays: "Mexique",
      },

      {
        libellePays: "Moldavie",
      },
      {
        libellePays: "Monaco",
      },

      {
        libellePays: "Mongolie",
      },

      {
        libellePays: "Monténégro",
      },
      {
        libellePays: "Montserrat",
      },
      {
        libellePays: "Mozambique",
      },
      {
        libellePays: "Myanmar",
      },
      {
        libellePays: "Namibie",
      },
      {
        libellePays: "Nauru",
      },
      {
        libellePays: "Népal",
      },
      {
        libellePays: "Nicaragua",
      },
      {
        libellePays: "Niger",
      },
      {
        libellePays: "Nigéria",
      },
      {
        libellePays: "Niue",
      },
      {
        libellePays: "Norvège",
      },
      {
        libellePays: "Nouvelle-Calédonie",
      },
      {
        libellePays: "Nouvelle-Zélande",
      },
      {
        libellePays: "Oman",
      },
      {
        libellePays: "Ouganda",
      },
      {
        libellePays: "Ouzbékistan",
      },
      {
        libellePays: "Pakistan",
      },
      {
        libellePays: "Palaos",
      },
      {
        libellePays: "Panama",
      },
      {
        libellePays: "Papouasie-Nouvelle-Guinée",
      },
      {
        libellePays: "Paraguay",
      },
      {
        libellePays: "Pays-Bas",
      },
      {
        libellePays: "Pérou",
      },
      {
        libellePays: "Philippines",
      },
      {
        libellePays: "Pitcairn",
      },
      {
        libellePays: "Pologne",
      },
      {
        libellePays: "Polynésie française",
      },
      {
        libellePays: "Porto Rico",
      },
      {
        libellePays: "Portugal",
      },
      {
        libellePays: "Qatar",
      },
      {
        libellePays: "R.A.S. chinoise de Hong Kong",
      },
      {
        libellePays: "R.A.S. chinoise de Macao",
      },
      {
        libellePays: "régions éloignées de l’Océanie",
      },
      {
        libellePays: "République centrafricaine",
      },
      {
        libellePays: "République démocratique du Congo",
      },
      {
        libellePays: "République dominicaine",
      },
      {
        libellePays: "République tchèque",
      },
      {
        libellePays: "Réunion",
      },
      {
        libellePays: "Roumanie",
      },
      {
        libellePays: "Royaume-Uni",
      },
      {
        libellePays: "Russie",
      },
      {
        libellePays: "Rwanda",
      },
      {
        libellePays: "Sahara occidental",
      },
      {
        libellePays: "Saint-Barthélémy",
      },
      {
        libellePays: "Saint-Kitts-et-Nevis",
      },
      {
        libellePays: "SM Saint-Marin",
      },
      {
        libellePays: "MF Saint-Martin",
      },
      {
        libellePays: "Saint-Pierre-et-Miquelon",
      },
      {
        libellePays: "Saint-Vincent-et-les Grenadines",
      },
      {
        libellePays: "Sainte-Hélène",
      },
      {
        libellePays: "Sainte-Lucie",
      },
      {
        libellePays: "Samoa",
      },
      {
        libellePays: "Samoa américaines",
      },
      {
        libellePays: "Sao Tomé-et-Principe",
      },
      {
        libellePays: "Sénégal",
      },
      {
        libellePays: "Serbie",
      },
      {
        libellePays: "Serbie-et-Monténégro",
      },
      {
        libellePays: "Seychelles",
      },
      {
        libellePays: "Sierra Leone",
      },
      {
        libellePays: "Singapour",
      },
      {
        libellePays: "Slovaquie",
      },
      {
        libellePays: "Slovénie",
      },
      {
        libellePays: "Somalie",
      },
      {
        libellePays: "Soudan",
      },
      {
        libellePays: "Sri Lanka",
      },
      {
        libellePays: "Suède",
      },
      {
        libellePays: "Suisse",
      },
      {
        libellePays: "Suriname",
      },
      {
        libellePays: "Svalbard et Île Jan Mayen",
      },
      {
        libellePays: "Swaziland",
      },
      {
        libellePays: "Syrie",
      },
      {
        libellePays: "Tadjikistan",
      },
      {
        libellePays: "Taïwan",
      },
      {
        libellePays: "Tanzanie",
      },
      {
        libellePays: "Tchad",
      },
      {
        libellePays: "Terres australes françaises",
      },
      {
        libellePays: "Territoire britannique de l'océan Indien",
      },
      {
        libellePays: "Territoire palestinien",
      },
      {
        libellePays: "Thaïlande",
      },
      {
        libellePays: "Timor oriental",
      },
      {
        libellePays: "Togo",
      },
      {
        libellePays: "Tokelau",
      },
      {
        libellePays: "Tonga",
      },
      {
        libellePays: "Trinité-et-Tobago",
      },
      {
        libellePays: "Tristan da Cunha",
      },
      {
        libellePays: "Tunisie",
      },
      {
        libellePays: "Turkménistan",
      },
      {
        libellePays: "Turquie",
      },
      {
        libellePays: "Tuvalu",
      },
      {
        libellePays: "Ukraine",
      },
      {
        libellePays: "Union européenne",
      },
      {
        libellePays: "Uruguay",
      },
      {
        libellePays: "Vanuatu",
      },
      {
        libellePays: "Venezuela",
      },
      {
        libellePays: "Viêt Nam",
      },
      {
        libellePays: "Wallis-et-Futuna",
      },
      {
        libellePays: "Yémen",
      },
      {
        libellePays: "Zambie",
      },
      {
        libellePays: "Zimbabwe",
      },
    ],
    //cette propriété est utilisée pour controler la bouton de vaidatation lors de l'edit
    indexForm: 1,
    editCaracteristiques: [],

    //map variables
    map: {},
    mark: {},
    geocoder: {},
    lat: null,
    lng: null,
    initialCoordinates: null,
    zoomLevel: 8,
  }),
  watch: {},
  methods: {
    close() {
      this.$emit("close");
      this.resetModal();
      this.step = 0;
    },
    back() {
      if (this.step == 0) {
        this.step = 0;
        return;
      }
      this.step--;
      console.log(this.step);
    },
    nextStep() {
    this.loading = true;
      switch (this.step) {
        case 0:
          this.nom = this.$refs.nomBat.value;
          if (
            (this.refCite =
              "" ||
              this.superficie == null ||
              this.superficie <= 3 ||
              this.nomCite == "")
          ) {
            this.customErrorMsg = `veuillez renseigner toutes les informations nécessaires.`;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          } else {
            // validation step 2 si tout est correct
            this.step++;
          }
          break;
        case 1:
          this.step++;
          break;
        case 2:
          this.step++;
          break;
        default:
          console.log(`Rien ne va plus`);
      }
      this.loading = false;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.photos = [];
      console.log("taille Totale", fileList.length - 1, "formaData", formData);
      for (let i = 0; i < fileList.length; i++) {
        //console.log("essai",fileList[i].path)
        this.photos.push(fileList[i].path);
      }
      console.log("photosTaille", this.photos.length);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("voulez vous supprimer cette image");
      if (r == true) {
        done();
      } else {
        console.log("erreur");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", fileList);
    },
    async getCites() {
      let cites = storage.get("cites");
      if (php.empty(cites)) {
        cites = await axios
          .get("cites")
          .then((response) => response.result || []);
        storage.set("cites", cites, 10);
      }
      this.cites = cites || [];

      if (!php.empty(this.batiment)) {
        this.nom = this.batiment.nomBatiment;
        this.ref = this.batiment.refBatiment;
        this.photo.batiment = this.batiment.image;
        this.idCite = this.batiment.idCite;
        console.log("entrée adresse 1", this.batiment);
        if (!php.empty(this.batiment.adresse)) {
          console.log("entrée adresse 2", this.batiment.adresse);
          this.pays = this.batiment.adresse.pays;
          this.ville = this.batiment.adresse.ville;
          this.quartier = this.batiment.adresse.quartier;
        }
        this.idCite = this.batiment.idCite;
        this.idCite = this.batiment.idCite;
      }
      //   else {
      //     this.cites.unshift({
      //       nomCite: "Créer une nouvelle cité",
      //       refCite: "Ajout d'une cité inexistante",
      //       idCite: -1,
      //     });
      //   }
      this.showOverlay = false;
    },
    resetModal() {
      (this.idCite = null),
        (this.nom = ""),
        (this.ref = ""),
        (this.superficie = null),
        (this.nomCite = -1),
        (this.refCite = null),
        (this.pays = ""),
        (this.ville = ""),
        (this.quartier = ""),
        (this.photo = { batiment: null, cite: null });
    },
    async submitForm(action) {
      this.loading1 = true;
      if (
        php.empty(this.nom) ||
        php.empty(this.ref) ||
        php.empty(this.idCite)
      ) {
        return App.error("Veuillez remplir le nom et la reference du batiment");
      }

      if (
        this.idCite == -1 &&
        (php.empty(this.nomCite) || php.empty(this.refCite))
      ) {
        return App.error("Veuillez entrer le nom et la reference de la cité");
      }

      this.showOverlay = true;

      let data = {
        nom: this.nom,
        idCite: this.idCite,
        ref: this.ref,
        superficie: this.superficie,
        photo: await this.$getBase64(this.photos[0]),
        batiments: [],
        adresse: {
          pays: this.pays,
          ville: this.ville,
          quartier: this.quartier,
          lat: this.lat,
          lon: this.lon,
        },
      };

      //code pour assurer la création ou la mise à jour d'un batiment
      if (this.action == "add") {
        if (data.idCite == -1) {
          try {
            let response = await axios.post("cites", {
              nom: this.nomCite,
              ref: this.refCite,
              photo: await this.$getBase64(this.photo.cite),
            });
            data.idCite = response.result.idCite;
          } catch (error) {
            return App.alertError(error.message);
          }
        }
        if (!php.empty(this.cite)) {
          /*let batiments = $(`#${this.repeaterId}`).repeaterVal().group

                    console.log(batiments);
                    //return;

                    if (php.empty(batiments[0].nom) || php.empty(batiments[0].ref)) {
                        return App.error('Vous devez remplir au moins les informations du premier batiment')
                    }
                    data.batiments = batiments*/
        }

        axios
          .post("batiments", data)
          .then((response) => {
            this.$emit("update", response.result);
            this.submitted = false;
            this.resetModal();
            this.close()
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.submitted = false;
            this.close()
            return App.notifyError(error.message);
          });
      }

      if (this.action == "edit" && !php.empty(this.batiment)) {
        console.log("data", data);
        axios
          .put(`batiments/${this.batiment.idBatiment}`, data)
          .then((response) => {
            this.$emit("batimentUpdated", {
              ...data,
              idBatiment: this.batiment.idBatiment,
            });
            this.submitted = false;
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.submitted = false;
            return App.notifyError(error.message);
          });
      }
      this.loading1 = false;
    },
    initMap() {
      this.initialCoordinates = new google.maps.LatLng(3.866667, 11.516667);
      this.zoomLevel = 12;
      var mapOptions = {
        zoom: this.zoomLevel,
        center: this.initialCoordinates,
        mapId: "5e906764d63d082e",
        tilt: 45,
        disableDefaultUI: true,
      };
      var bounds = {
        north: 3.866667599,
        south: 3.86,
        east: 11.51,
        west: 11.5,
      };
      this.map = new google.maps.Map(this.$refs.carte, mapOptions);
      this.marker = new google.maps.Marker({
        position: this.map.getCenter(),
        map: this.map,
        title: "point de repère",
        animation: google.maps.Animation.DROP,
        draggable: false,
      });

      // form-research
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById("location")
      );

      const input = document.getElementById("location-input");
      const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.bindTo("bounds", this.map);

      autocomplete.addListener("place_changed", () => {
        this.marker.setVisible(false);

        const place = autocomplete.getPlace();

        console.log(place);
        this.lon = place.geometry.location.lng();
        this.lat = place.geometry.location.lat();
        this.initialCoordinates = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        this.ville = place.address_components[1].long_name;
        this.quartier = place.name;
        this.location = this.$refs.location.value;
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }

        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);
      });
      //centrer le marker lorsque l'utilisateur bouge la carte
      let vm = this;
      google.maps.event.addListener(this.map, "center_changed", function () {
        // 0.1 seconds after the center of the map has changed,
        // set back the marker position.
        globalThis.setTimeout(function () {
          var center = vm.map.getCenter();
          vm.lon = center.lng();
          vm.lat = center.lat();
          //console.log(center);
          console.log(vm.lon);
          vm.marker.setPosition(center);
        }, 100);
      });
    },
  },
  async mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute("defer", "true");
    googleScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4wnGV-GeVMiv-_yT2qO_iKyaM_bMGX7w&libraries=places"
    );
    document.head.appendChild(googleScript);
    this.getCites();
    setTimeout(() => {
      this.initMap();
    }, 1500);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
.parent-form-container{
  height: 90%;
  max-height: 700px;
  width: 70%;
  max-width: 900px;
  background: white;
  border-radius: 8px;
  cursor: auto;

}
.parent-form-container .form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-container header {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid #eee;
}
.form-container header .header-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.header-part span {
  font-weight: bold;
  font-size: 1.5rem;
}
.header-part .clos {
  font-size: 1.8rem;
  color: #191c22;
  cursor: pointer;
}
header .progress-area {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.progress-area .step-item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: auto;
  height: auto;
  user-select: none;
  cursor: pointer;
}
.step-item span {
  font-weight: 500;
  color: #424242;
}
.step-item span.active {
  color: #f5365c;
}
.step-item .bullet {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #424242;
  overflow: visible;
}
.step-item .bullet span {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #424242;
}
.step-item .bullet div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #424242;
}
.step-item .bullet div i {
  color: white;
  font-size: 1.2rem;
}
.bullet.active {
  border: 1px solid #f5365c;
}
.bullet.active span {
  color: #f5365c;
}
.step-item .step .bullet:before,
.step-item .step .bullet:after {
  position: absolute;
  content: "";
  bottom: 11px;
  right: -51px;
  height: 3px;
  width: 44px;
  background: #191c22;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-top: 1px solid #eee;
}
.form-container .main-form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 240px);
}
.main-form .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.process-item {
  padding: 0 5%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: 0.3s ease all;
}
.basic-data {
  padding: 30px 5% 0;
  width: 100%;
  height: 100%;
}
.process-item.photos {
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.photos span {
  margin-bottom: 50px;
  display: inline-block;
  max-width: 550px;
  text-align: center;
}
.input-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  width: 100%;
  height: auto;
}
.title {
  font-weight: 600;
}
.input-container .input-item {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
/* .input-item select .batiment-option {
}
.batiment-option .bat-name {
} */
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-container.radio label,
.input-container.index label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45%;
  background: transparent;
  background: #f0f0f0;
  border-radius: 8px;
}
.input-container.radio label.check,
.input-container.index label.check {
  background: #191c22;
  color: white;
}
.input-container.radio label input {
  display: none;
}
.process-item.location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.location .input-container {
  display: flex;
  flex-direction: column;
}
.input-container .more-location {
  height: auto;
  width: 100%;
}
.semi-container {
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.semi-container .input-item {
  width: 48%;
}
.process-item.location .map-area {
  padding: 20px 0;
}
.map-area .map-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(187, 187, 187);
}
.map-area .research-input {
  background: white;
  padding-left: 5px;
  margin: 10px;
  display: flex;
  height: 40px;
  width: 93%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.research-input div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border-radius: 8px;
}
.research-input div i {
  font-size: 1.3rem;
  line-height: 0;
}
.research-input input {
  padding-left: 5px;
  height: 100%;
  width: calc(100% - 30px);
  border: none;
  border-radius: 8px;
  background: transparent;
}
.process-item.configuration {
  padding-top: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.process-item.configuration .config-group {
  display: flex;
  align-items: center;
  height: auto;
  width: 85%;
}
.process-item.configuration .config-group .barre {
  margin: 0 20px 15px;
  height: 50%;
  width: 2px;
  background: #eee;
}
.process-item.configuration .barre-h {
  margin: 30px 0;
  height: 1px;
  width: calc(80% + 42px);
  background: #eee;
}
.process-item.configuration .other-config-container {
  height: auto;
  width: 45%;
}
.other-config {
  margin-top: 20px;
  display: flex;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 50px;
}
.other-config select {
  height: 100%;
  width: calc(100% - 100px);
  border-radius: 8px 0 0 8px;
  border: none;
}
.other-config input {
  height: 100%;
  width: 100px;
  border-radius: 0 8px 8px 0;
  border: none;
}
.process-item.configuration .config-item {
  margin-bottom: 15px;
  padding: 0 5px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 47%;
  border-radius: 8px;
  border: 1px solid #eee;
}
.config-item .title-item {
  font-weight: 500;
}
.config-item .config-controller {
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
}
.config-controller .control-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 40px;
  background: #f5f5f5;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  color: #424242;
  transition: all 0.2s ease;
}
.config-controller .control-btn:active {
  transform: scale(0.96);
}
.config-controller .item-value {
  margin: 0 10px;
}
.process-item.end {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.process-item.end .title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}
.process-item.end p {
  margin-top: 1.2rem;
  text-align: center;
  width: 80%;
  max-width: 600px;
  color: #424242;
}
</style>
