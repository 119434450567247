<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          data-toggle="tab"
          href="#"
          class="nav-link"
          :class="{ active: section == 'vacances' }"
          @click.prevent="section = 'vacances'"
          >{{$t('data.logement_analyse_vacances_immobiliere')}}</a
        >
      </li>
    </ul>
    <div class="tab-content mt-3">
      <div class="tab-pane fade show active">
        <b-container fluid v-if="section == 'vacances'">
          <dl class="row">
            <dd class="col-4">Coût moyen de la vacance</dd>
            <dd class="col font-weight-bold fa-lg">{{ vacances.reduce((a, c) => a + c.items.reduce((i, j) => i + j.total, 0), 0) | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</dd>
          </dl>
          <b-table-simple sticky-header="500px">
            <b-thead>
              <b-tr>
                <b-th class="bg-light">Logements</b-th>
                <b-th class="bg-light">Années</b-th>
                <b-th class="bg-light">Mois</b-th>
                <b-th class="bg-light">Montant</b-th>
                <b-th class="bg-light">Total</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(vacance, i) in vacances" :key="i">
                <b-td class="border"><span>{{ vacance.logement }}</span></b-td>
                <b-td class="p-0" colspan="4">
                  <b-table-simple class="m-0">
                    <b-tbody>
                      <b-tr v-for="(periode, j) in vacance.items" :key="j">
                        <b-td class="border">{{ periode.annee }}</b-td>
                        <b-td class="p-0">
                          <b-table-simple class="m-0">
                            <b-tbody>
                              <b-tr v-for="(item, k) in periode.items" :key="k">
                                <b-td>{{ (k + 1) }}</b-td>
                                <b-td>{{ $dayjs(`${periode.annee}-${item.mois}-01`).format('MMMM') }}</b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-td>
                        <b-td class="p-0">
                          <b-table-simple class="m-0">
                            <b-tbody>
                              <b-tr v-for="(item, k) in periode.items" :key="k">
                                <b-td>{{ item.montant  | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-td>
                        <b-td class="border"><span class="font-weight-bold">{{ periode.total | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters} from "vuex"
const php = require("phpjs");
export default {
  props: {
    idBatiment: { type: Number, required: true },
  },
  computed: {
    ...mapGetters(['user']),
  },
  data: () => ({
    section: "vacances",
    vacances: [],

    devise: null
  }),
  mounted() {
    this.devise= this.user.preferences.devise || 'F'
    this.getVaccances();
  },
  methods: {
    async getVaccances() {
      this.vacances = await axios.get('batiments/' + this.idBatiment + '/vacances').then(response => response.result);
    },
  },
};
</script>